
function data() {
  return {
    page: 1,
    recordPages: 5,
    totalPages: 0,
    totalRecords: 0,
    searchKey: '',
  };
}
//methods
function searchAdvanceFilter(getDatatableInfo = null) {  
  this.page = 1;
  getDatatableInfo();
}
async function changePagination(event, getDatatableInfo = null) {
  this.page = 1;
  this.recordPages = event;
  await getDatatableInfo();
}
async function updatePage(event, getDatatableInfo = null) {
  this.page = event; 
  await getDatatableInfo();
}
function clearFilters(getDatatableInfo = null) {

  this.page = 1;
  this.searchKey= '',
  getDatatableInfo();
}

// computed 


export default {
  data,
  methods: {
    searchAdvanceFilter,
    changePagination,
    updatePage,
    clearFilters,
  },
  computed: {

  },
}