<template>
  <CModalExtended
    :title="$t('label.errors')"
    color="dark"
    size="xl"
    :show.sync="modalActive"
  >
    <dataTableExtended
      class="align-center-row-datatable"
      hover
      small
      sorter
      column-filter
      :table-filter="tableText.tableFilterText"
      :items-per-page-select="tableText.itemsPerPageText"
      :noItemsView="tableText.noItemsViewText"
      :items="formatedItems"
      :fields="fields"
      :loading="isLoading"
      :items-per-page="5"
      :active-page="activePage"
      pagination
    >
    </dataTableExtended>

    <template #footer>
      <CButton shape="square" color="success" class="mr-2" @click="onBtnExportErrors(items, getReportTitle, getHeaderInfo)">
        <CIcon name="cil-file-excel" />&nbsp; XLSX
      </CButton>
      <CButton shape="square" color="wipe" @click="toggle(false)">
        <CIcon name="x" />&nbsp; {{ $t("button.cancel") }}
      </CButton>
    </template>
  </CModalExtended>
</template>

<script>
import ModalMixin from "@/_mixins/modal";
import { tableText } from "@/_helpers/funciones";
import MetalScrap from '@/_mixins/metalscrap';
import { formatMilDecimal } from '@/_validations/validacionEspeciales';

function fields() {
  return [
    {
      key: "Nro",
      label: "#",
      _style: "width:1%;",
      filter: false,
    },
    { key: "NroBl", label: 'BL', },
    { key: "DriverName", label: this.$t("label.driver")},
    { key: "LicensePlate", label: this.$t("label.vehicle")},
    { key: "NetWeight", label: this.$t("label.netWeight") + ' (KG)'},
    { key: "Column", label: this.$t("label.column") },
    { key: "Value", label: this.$t("label.value") },
    { key: "Error", label: this.$t("label.error") },
  ];
}
function cellTableClasses() {
  return {
    Nro: "align-middle",
    NroBl: "align-middle",
    DriverName: "align-middle",
    LicensePlate: "align-middle",
    NetWeight: "align-middle",
    Column: "align-middle",
    Value: "align-middle",
    Error: "align-middle",
  };
}

//Data
function data() {
  return {
    modalActive: this.modal,
    isLoading: false,
    activePage: 1,
    items: [],
  };
}

//Methods
function toggle(newVal) {
  if(newVal)
    this.getFileErrors();
  this.modalActive = newVal;
}

function getFileErrors() {
  this.isLoading = true;

  this.$http
    .get("OrderErrorList-by-OrderFileId",{
      OrderFileId: this.orderFileId,
    })
    .then((response) => {
      this.items = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.isLoading = false;
    });
}

//Computeds
function formatedErrors() {
  if (this.items.length == 0) return [];

  let arrErrors = [];
  for (let index = 0; index < this.items.length; index++) {
    if (!Array.isArray(this.items[index].JsonError)) {
      for (
        let j = 0;
        j < this.items[index].JsonError.ErrorJson.length;
        j++
      ) {
        arrErrors.push({
          Nro: this.items[index].Nro,
          NroBl: this.items[index].NroBl,
          DriverName: this.items[index].DriverName ? this.items[index].DriverName : 'N/A',
          LicensePlate: this.items[index].LicensePlate ? this.items[index].LicensePlate : 'N/A',
          NetWeight: this.items[index].NetWeight ? formatMilDecimal(this.items[index].NetWeight) : 'N/A',
          Column: this.items[index].JsonError.ErrorJson[j].ColumnName,
          Value: this.items[index].JsonError.ErrorJson[j].ColumnValue,
          Error:
            this.$i18n.locale == "es"
              ? this.items[index].JsonError.ErrorJson[j].ErrorMessage
              : this.items[index].JsonError.ErrorJson[j].ErrorMessageEn,
        });
      }
    }
  }

  return arrErrors;
}
function formatedItems() {
  return this.formatedErrors.map((item) =>
    Object.assign({}, item, {
      _cellClasses: this.cellTableClasses,
    })
  );
}

function getHeaderInfo() {
  if(this.items.length == 0) return [];

  return [
    {
      label: 'BL',
      value: this.items[0].NroBl,
    },
    {
      label: this.$t("label.consignee"),
      value: this.items[0].ClientName,
    },
    {
      label: this.$t("label.yard"),
      value: this.items[0].YardName,
    },
  ]
}
function getReportTitle() {
  if(this.items.length == 0) return [];

  return `${this.$t('label.scrapReception')} - ${this.items[0].FileName}`;
}

export default {
  name: "errors-modal",
  mixins: [ModalMixin, MetalScrap],
  props: {
    modal: Boolean,
    orderFileId: {
      type: String,
      default: '',
    },
  },
  data,
  methods: {
    toggle,
    getFileErrors,
  },
  computed: {
    fields,
    formatedItems,
    cellTableClasses,
    formatedErrors,
    tableText,
    getHeaderInfo,
    getReportTitle,
  },
  watch: {
    modal: function (val) {
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
  },
};
</script>