<template>
  <div>
    <loading-overlay :active="loading" :is-full-page="true" loader="bars" />
    <CRow class="mt-1">
      <CCol sm="2" class="center-field">
        <div class="position-relative">
          <CSelect
            size="sm"
            :label="'BL'"
            addLabelClasses="text-right"
            :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }"
            :placeholder="$t('label.select')"
            :options="blOptions"
            :disabled="isEmpty(blOptions)"
            :value.sync="$v.nroBl.$model"
            :invalid-feedback="errorMessage($v.nroBl)"
            :is-valid="hasError($v.nroBl)"
          >
          </CSelect>
          <loading v-show="loadingBl" element="select" />
        </div>
      </CCol>
      <CCol sm="3" class="center-field">
        <CInput
          size="sm"
          :label="$t('label.client')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }"
          addLabelClasses="text-right"
          :placeholder="''"
          v-uppercase
          disabled
          v-model.trim="clientName"
        />
      </CCol>
      <CCol sm="4" class="center-field d-flex align-items-start">
        <div class="position-relative">
          <CSelect
            size="sm"
            :label="$t('label.yard')"
            addLabelClasses="text-right"
            :horizontal="{ label: 'col-sm-4', input: 'col-sm-8' }"
            :placeholder="$t('label.select')"
            :options="yardOptions"
            :disabled="isEmpty(yardOptions)"
            :value.sync="$v.yard.$model"
            :invalid-feedback="errorMessage($v.yard)"
            :is-valid="hasError($v.yard)"
          >
          </CSelect>
          <loading v-show="isLoadingYard" element="select" />
        </div>
        <CButton
          size="sm"
          class="ml-1"
          shape="square"
          color="excel"
          :href="getFormatURL"
          download
          v-c-tooltip="{
            placement: 'top-end',
            content: `${$t('label.download')} ${$t('label.format')}`,
          }"
        >
          <CIcon name="cil-file-excel" />
        </CButton>
      </CCol>
      
      <CCol sm="3" class="d-flex align-items-start justify-content-end">
        <CButtonGroup>
          <CButton
            size="sm"
            class="mr-1"
            shape="square"
            color="excel"
            @click="getReportList"
            v-c-tooltip="{
              placement: 'top-end',
              content: `${$t('label.download')} XLSX`,
            }"
          >
            <CIcon name="cil-file-excel" />&nbsp;XLSX
          </CButton>
          <CButton
            size="sm"
            shape="square"
            color="watch"
            @click="getReportList(false)"
            v-c-tooltip="{
              placement: 'top-end',
              content: `${$t('label.download')} CSV`,
            }"
          >
            <CIcon name="cil-file-excel" />&nbsp;CSV
          </CButton>
        </CButtonGroup>
      </CCol>
    </CRow>
    <CRow class="mb-1">
      <CCol sm="12">
        <CCard bodyWrapper>
          <CRow>
            <CCol sm="12" class="mb-3">
              <vue-dropzone
                ref="myVueDropzone"
                id="dropzone"
                :options="dropZoneOptions"
                :useCustomSlot="true"
                v-on:vdropzone-files-added="sendingEvent"
                v-on:vdropzone-removed-file="deleteFile"
                v-on:vdropzone-error="DropzoneError"
              >
                <div class="dropzone-custom-content">
                  <h3 class="dropzone-custom-title">
                    <CIcon name="cil-cloud-upload" /> ¡{{
                      $t("label.dragAndDropToUploadContent")
                    }}!
                  </h3>
                  <div class="subtitle justify-content-center">
                    ...{{ $t("label.orClickToSelectFileFromYourComputer") }}
                  </div>
                </div>
              </vue-dropzone>
            </CCol>
            <CCol sm="11">
              <CInput
                :label="$t('label.description')"
                horizontal
                addLabelClasses="text-right"
                :placeholder="$t('label.documentDescription')"
                v-uppercase
                disabled
                v-model.trim="fileDescription"
              />
            </CCol>
            <CCol sm="1" class="d-flex align-items-start justify-content-end">
              <CButton
                color="add"
                v-c-tooltip="{
                  content: $t('label.add'),
                  placement: 'top-end',
                }"
                size="sm"
                @click.stop="submitReceptionFile"
              >
                <div v-if="!isSubmit">
                  <CIcon name="checkAlt" />
                </div>
                <div v-if="isSubmit">
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              </CButton>
            </CCol>
          </CRow>
        </CCard>
      </CCol>
      <CCol sm="12">
        <hr />
        <dataTableExtended
          class="align-center-row-datatable"
          hover
          sorter
          column-filter
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :items="formatedEntryList"
          :fields="fieldsReception"
          :loading="loadingList"
          :noItemsView="tableText.noItemsViewText"
          :items-per-page="5"
          pagination
          @filtered-items-change="getFilteredList"
        >
          <!-- <template #over-table>
            <div class="row justify-content-start" style="margin-top: -2.8rem">
              <div class="col-auto px-2">
                <label class="p-1 mt-1"
                  >{{ $t("label.filter") }}<b>: </b></label
                >
              </div>
              <div class="col-auto px-0">
                <CInput
                  v-model="searchKey"
                  :placeholder="$t('label.enterSearch')"
                  v-uppercase
                  class="col-sm-12 px-0"
                />
              </div>
              <div class="col-auto px-2">
                <CButton
                  color="watch"
                  class="d-flex align-items-center"
                  v-c-tooltip="{
                    content: $t('label.search'),
                    placement: 'top',
                  }"
                  @click="searchAdvanceFilter(getEntryList)"
                >
                  <CIcon name="cil-search" />
                </CButton>
              </div>
              <div class="col-auto px-0">
                <CButton
                  color="wipe"
                  class="d-flex align-items-center"
                  v-c-tooltip="{
                    content: $t('label.reset') + $t('label.filter'),
                    placement: 'top',
                  }"
                  @click="clearFilters"
                >
                  <CIcon name="cil-brush-alt" />
                </CButton>
              </div>
            </div>
          </template> -->
          <template #loading>
            <loading />
          </template>
          <template #Status="{ item }">
            <td class="center-cell">
              <CBadge :color="getBadge(item.Status)">
                {{ $t("label." + item.Status) }}
              </CBadge>
            </td>
          </template>

          <template #Detalle="{ item }">
            <td class="text-center align-middle">
              <CButton
                shape="square"
                color="edit"
                size="sm"
                v-c-tooltip="$t('label.edit')"
                @click="toggleModal(true, item)"
              >
                <CIcon name="pencil" />
              </CButton>
            </td>
          </template>
          <!--paginacion-->
          <!-- <template #under-table>
            <div v-if="totalPages > 0" class="ml-3 mb-3">
              <CPagination
                :activePage.sync="page"
                :pages="totalPages"
                size="sm"
                @update:activePage="updatePage($event, getEntryList)"
              />
            </div>
          </template> -->
        </dataTableExtended>
      </CCol>
    </CRow>
    <EntryOrderModal
      :modal.sync="modal"
      :entryOrderId="entryOrderId"
      :edit="edit"
      @submited="handleSubmit"
    />
    <AlertModal
      :modal.sync="alertModal"
      :receptionList="receptionFile"
      :fileName="fileName"
      :correct="correct"
      :errors="errors"
    />
  </div>
</template>

<script>
import { DateFormater, alertPropertiesHelpers } from "@/_helpers/funciones";
import { formatMilDecimal } from '@/_validations/validacionEspeciales';
import FileUploadValidation from "@/_validations/metalscrap/FileUploadValidation";
import UpperCase from "@/_validations/uppercase-directive";
import General from "@/_mixins/general";
import MetalScrap from "@/_mixins/metalscrap";

import EntryOrderModal from "./entry-order-modal";
import AlertModal from "./alert-modal";

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapState } from "vuex";
import DatatablePagination from "@/_mixins/datatablePagination";

function fieldsReception() {
  return [
    {
      key: "Nro",
      label: "#",
      _style: "width:1%;",
      _classes: "text-center",
      filter: false,
    },
    { key: "NroBl", label: "BL" },
    { key: "Client", label: this.$t("label.client") },
    { key: "Yard", label: this.$t("label.yard") },
    { key: "IdDriver", label: this.$t("label.idDriver") },
    { key: "Driver", label: this.$t("label.driver") },
    { key: "Plate", label: this.$t("label.plate") },
    { key: "NroGuide", label: this.$t("label.nroGuide") },
    { key: "NroControl", label: this.$t("label.nroControl") },
    { key: "Weight", label: this.$t("label.netWeight") + "(KG)" },
    { key: "Usuario", label: this.$t("label.user") },
    { key: "Fecha", label: this.$t("label.date"), _classes: "text-center" },
    { key: "Status", label: this.$t("label.status"), _classes: "text-center" },
    {
      key: "Detalle",
      label: "",
      sorter: false,
      filter: false,
      _styles: "width: 1%;",
    },
  ];
}
function cellTableClasses() {
  return {
    Nro: "align-middle",
    NroBl: "align-middle",
    Client: "align-middle",
    Yard: "align-middle",
    IdDriver: "align-middle",
    Driver: "align-middle",
    Plate: "align-middle",
    NroGuide: "align-middle",
    NroControl: "align-middle",
    Weight: "align-middle",
    Usuario: "align-middle",
    Fecha: "align-middle",
    Status: "align-middle",
  };
}

//DATA
function data() {
  return {
    items: [],
    loadingList: false,
    loadingBl: false,
    loading: false,
    billOfLoadings: [],
    nroBl: "",
    fileDescription: "",
    docFile: null,
    modal: false,
    edit: false,
    entryOrderId: "",
    alertModal: false,
    isSubmit: false,
    uploadPercent: 0,
    responsePercent: 0,
    receptionFile: [],
    fileName: "",
    isDeactivate: false,
    clientName: "",
    yards: [],
    yard: '',
    isLoadingYard: false,
    clientTpId: '',
    correct: 0,
    errors: 0,
    filteredList: [],
  };
}

//METHOD
function loadData() {
  this.loadingBl = true;
  this.loadingList = true;

  let entryOrderJson = JSON.stringify({ 
    EntryOrderJson: [
      { 
        SearchKey: this.searchKey,
        CompanyBranchId: this.getBranchId,
        YardId: this.yard,
        ClientTpId: this.clientTpId,
        ControlNumber: '',
        DateFrom: '',
        DateTo: '',
      }
    ] 
  });

  
  let rutaEntryOrders = `EntryOrder-list?PageNumber=${this.page}&TotalRecords=${this.recordPages}&fgList=1&EntryOrderJson=${entryOrderJson}`;

  let peticiones = [
    this.$http.ejecutar("GET", "BillOfLadingReception-list", {
      CompanyBranchId: this.getBranchId,
    }),
    // this.$http.ejecutar("GET", rutaEntryOrders),
  ];

  Promise.all(peticiones)
    .then((responses) => {
      this.billOfLoadings = responses[0].data.data;
      // if (responses[1].data.data) {
      //   this.items = responses[1].data.data.data;
      //   this.totalPages = responses[1].data.data.TotalPages;
      //   this.totalRecords = responses[1].data.data.TotalRecords;
      // } else {
      //   this.items=[];
      //   this.totalPages = 0;
      //   this.totalRecords = 0;
      // }
    })
    .catch((err) => {
      this.notifyError({ text: err,});
    })
    .then(() => {
      this.loadingBl = false;
      this.loadingList = false;
    });
}
function getEntryList() {
  //this.loadingList = true;

  let entryOrderJson = JSON.stringify({ 
    EntryOrderJson: [
      { 
        SearchKey: this.searchKey,
        CompanyBranchId: this.getBranchId,
        YardId: this.yard,
        ClientTpId: this.clientTpId,
        ControlNumber: '',
        DateFrom: '',
        DateTo: '',
      }
    ] 
  });

  


  let ruta = `EntryOrder-list?PageNumber=${this.page}&TotalRecords=${this.recordPages}&fgList=1&EntryOrderJson=${entryOrderJson}`;

  this.$http.get(ruta)
    .then((response) => {
      if (response.data.data) {
        this.items = response.data.data.data;
        this.totalPages = response.data.data.TotalPages;
        this.totalRecords = response.data.data.TotalRecords;
      } else {
        this.items=[];
        this.totalPages = 0;
        this.totalRecords = 0;
      }
    })
    .catch((err) => {
      this.notifyError({ text: err,});
    })
    .then(() => {
      this.loadingList = false;
    });
}

function sendingEvent(files) {
  let ext = "";
  if (files[0].name) {
    ext = files[0].name.substring(
      files[0].name.lastIndexOf(".") + 1,
      files[0].name.length
    );

    let format = ".xlsx";
    if (`.${ext.toLowerCase()}` == format) {
      this.docFile = files[0];
    } else {
      this.$refs.myVueDropzone.removeAllFiles();
      let msg = `${this.$t("label.docTypeInvalid")}`;
      this.$swal.fire({
        icon: "error",
        title: "",
        text: msg,
      });
    }
  }
}
function deleteFile(file) {
  this.docFile = null;
}
function DropzoneError(file) {
  this.$refs.myVueDropzone.removeFile(file);
}
function resetData() {
  this.fileDescription = "";
  this.$refs.myVueDropzone.removeAllFiles();
  this.docFile = null;
  this.uploadPercent = 0;
  this.responsePercent = 0;
}
function handleSubmit() {
  this.getEntryList();
}
function toggleModal(edit = false, item = null) {
  if (edit && item) {
    this.edit = edit;
    this.entryOrderId = item.OrderId;
  }
  this.modal = true;
}
function toggleAlert() {
  this.alertModal = true;
}
async function submitReceptionFile() {
  try {
    this.$v.nroBl.$touch();
    this.$v.yard.$touch();
    if (!this.nroBl || !this.yard || !this.docFile) {
      throw this.$t("label.errorsPleaseCheck");
    }
    this.isSubmit = true;
    let formData = new FormData();
    formData.append("BillOfLadingYardId", this.nroBl);
    formData.append("ClientTpId", this.clientTpId);
    formData.append("YardId", this.yard);
    formData.append("files", this.docFile);

    //console.log('-------------', entryOrderJson)
    ///return false;

    this.$http
      .post("OrderFileInsert", formData, {
        baseURL: `${this.$store.getters["connection/getApiBase"]}`,
        onUploadProgress: function (progressEvent) {
          this.uploadPercent = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
      .then((response) => {
        this.$notify({
          group: "container",
          title: "¡Exito!",
          text: response.data.data[0].Json[0].Response,
          type: "success",
        });
        this.receptionFile = response.data.data[0].Json[0].OrderJson;
        this.items = response.data.data[0].Json[0].OrderJson.map((item) =>
          Object.assign({}, item, {
            TransaLogin: response.data.data[0].Json[0].TransaLogin,
            TransaRegDate: response.data.data[0].Json[0].TransaRegDate,
          })
        );
        this.correct = response.data.data[0].Json[0].Correct;
        this.errors = response.data.data[0].Json[0].Error;
        this.fileName = this.docFile.name;
        this.toggleAlert();
        this.resetData();
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
      .then(() => {
        this.isSubmit = false;
        this.uploadPercent = 0;
        this.responsePercent = 0;
      });
  } catch (e) {
    this.notifyError({ text: e });
  }
}
function notifyError(properties = {}) {
  let notifyProperties = {
    group: "container",
    title: "¡ Error !",
    text: "¡" + this.$t("label.errorsPleaseCheck") + "!",
    type: "error",
  };
  notifyProperties = Object.assign(notifyProperties, properties);
  this.$notify(notifyProperties);
}
function clearFilters() {
  this.nroBl = '';
  this.yards = [];
  this.yard = '';
  this.clientTpId = '';
  this.clientName = '';

  this.page = 1;
  this.searchKey= '',
  this.getEntryList();
  this.$v.$reset();
}
function getReportList(excel = true) {
  if(this.filteredList.length == 0) return;

  this.onBtnExportAll(this.items, excel, `${this.$t('label.metalScrapReception')}`);
}
function getFilteredList(arr) {
    this.filteredList = arr;
}
// COMPUTED
function formatedEntryList() {
  return this.items.map((item) =>
    Object.assign({}, item, {
      Nro: item.Nro,
      Client: item.ClientName,
      Yard: item.YardName,
      IdDriver: item.DriverCi,
      Driver: item.DriverName,
      Plate: item.LicensePlate,
      NroGuide: item.GuideNumber,
      NroControl: item.ControlNumber,
      Weight: formatMilDecimal(item.NetWeight),
      Usuario: item.TransaLogin ? item.TransaLogin : "N/A",
      Fecha: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : "N/A",
      Status: item.Status,
      _classes: item.Status === "INACTIVO" ? "table-danger" : "",
      _cellClasses: this.cellTableClasses,
    })
  );
}
function dropZoneOptions() {
  const token = this.user.token;
  return {
    url: `${process.env.VUE_APP_API_BASE}files/`,
    autoProcessQueue: false,
    maxFiles: 1,
    addRemoveLinks: true,
    dictRemoveFile: `${this.$t("label.delete")}`,
    dictCancelUpload: `${this.$t("label.cancelUpload")}`,
    maxfilesexceeded(files) {
      this.removeAllFiles();
      this.addFile(files);
    },
    acceptedFiles: ".xlsx",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
}
function blOptions() {
  return this.billOfLoadings.map((company) =>
    Object.assign({}, company, {
      label: company.NroBl,
      value: company.BillOfLadingYardId,
    })
  );
}
function isEmpty(arr) {
  return arr.length == 0;
}
function yardOptions() {
  return this.yards.map((yard) =>
    Object.assign({}, yard, {
      label: yard.YardName,
      value: yard.YardId,
    })
  );
}
function getBranchId() {
  return this.branch ? this.branch.CompanyBranchId : "";
}
function getFormatURL() {
  return `${this.$store.getters["connection/getBase"]}Download/FormatoOrdenesDeIngresoSBS.xlsx`;
}

export default {
  name: "massive-load-tab",
  mixins: [General, DatatablePagination, MetalScrap],
  props: {
    activeTab: {
      type: Number,
      default: 0,
    },
  },
  validations: FileUploadValidation,
  directives: UpperCase,
  data,
  components: {
    EntryOrderModal,
    AlertModal,
    vueDropzone: vue2Dropzone,
  },
  methods: {
    sendingEvent,
    deleteFile,
    DropzoneError,
    loadData,
    getEntryList,
    handleSubmit,
    toggleModal,
    toggleAlert,
    resetData,
    submitReceptionFile,
    notifyError,
    isEmpty,
    clearFilters,
    getReportList,
    getFilteredList,
  },
  watch: {
    activeTab: function (newTab) {
      if (newTab == 1) {
        // this.getEntryList();
        this.nroBl = '';
        this.items = [];
        this.$v.$reset();
      }
    },
    modal: function (val) {
      if (!val) {
        this.edit = false;
        this.entryOrderId = "";
        this.receptionFile = [];
        this.fileName = "";
      }
    },
    alertModal: function (val) {
      if (!val) {
        this.receptionFile = [];
        this.fileName = "";
        this.correct = 0;
        this.errors = 0;
        // this.getEntryList();
      }
    },
    errosModal: function (val) {
      if (!val) {
        this.errorsList = [];
      }
    },
    docFile: function (val) {
      if (val) {
        this.fileDescription = val.name;
      }
    },
    nroBl: function (val, oldVal) {
      if(val != oldVal) {
        this.items = [];
      }
      if(val) {
        // this.getEntryList();

        let found = this.billOfLoadings.find((item) => item.BillOfLadingYardId == val);
        if(found){
          this.clientName = found.ClientName;
          this.clientTpId = found.ClientTpId;
          this.yard = '';
          this.yards = found.YardJson ? [...found.YardJson] : [];
        }
      } else {
        this.yards = [];
        this.yard = '';
        this.clientTpId = '';
        this.clientName = '';
      }
    },
    yard: function (newVal, oldVal) {
      if(newVal != oldVal) {
        this.items = [];
        // this.getEntryList();
      }
    },
  },
  computed: {
    fieldsReception,
    formatedEntryList,
    dropZoneOptions,
    blOptions,
    cellTableClasses,
    yardOptions,
    getBranchId,
    getFormatURL,
    ...mapState({
      user: (state) => state.auth.user,
      branch: (state) => state.auth.branch,
    }),
  },
  created() {
  },
  mounted() {
    this.loadData();
  },
};
</script>
<style scoped>
.upload-progress {
  width: 100%;
}
</style>